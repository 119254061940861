import React, { FC } from 'react';

import { AuthorInfoProps } from './models';

import './AuthorInfo.scss';

const AuthorInfo: FC<AuthorInfoProps> = ({ name, jobTitle }) => {
  if (!name && !jobTitle) return null;

  return (
    <div className="colourful-quote-slider__author-info">
      {name ? <h3 className="colourful-quote-slider__author-name h6">{name}</h3> : null}
      {jobTitle ? <span className="caption">{jobTitle}</span> : null}
    </div>
  );
};

export default AuthorInfo;
