import { useEffect, useState } from 'react';

const useOnScreen = (ref, threshold) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        {
          threshold,
        }
      );

      observer.observe(ref.current);
      // Remove the observer as soon as the component is unmounted+

      return () => {
        observer.disconnect();
      };
    }
    setIntersecting(true);
  }, []);

  return isIntersecting;
};

export default useOnScreen;
