import React, { FC } from 'react';

import VideoIframe from 'components/VideoIframe';

import { VideoItemProps } from './models';

const VideoItem: FC<VideoItemProps> = ({ video, image, playButtonText, ariaId }) => (
  <div className="video-and-image-with-text__video">
    <VideoIframe
      url={video}
      placeholderImage={image}
      playButtonText={playButtonText}
      ariaId={ariaId}
    />
  </div>
);

export default VideoItem;
