import React, { FC } from 'react';

import ColorCtaCards from './ColorCtaCards';
import { ColorCtaCardsProps } from './ColorCtaCards/models';
import ImageCtaCards from './ImageCtaCards';
import { ImageCtaCardsProps } from './ImageCtaCards/models';
import { CtaCardsProps } from './models';

import './CtaCards.scss';

const CtaCards: FC<CtaCardsProps> = ({ module }) =>
  Object.prototype.hasOwnProperty.call(module.cards[0], 'colorStyle') ? (
    <ColorCtaCards {...(module.cards[0] as ColorCtaCardsProps)} />
  ) : (
    <ImageCtaCards {...(module.cards[0] as ImageCtaCardsProps)} />
  );

export default CtaCards;
