import React, { FC } from 'react';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import Image from 'common/Image';
import LinkItem from 'common/LinkItem';

import { HeroQuoteProps } from './models';
import QuoteAuthor from './QuoteAuthor';

import './HeroQuote.scss';

const HeroQuote: FC<HeroQuoteProps> = ({ module }) => (
  <section
    className={classNames('hero-quote section', { 'hero-quote--right': module.isImageOnRight })}
  >
    <Container>
      <Row>
        {module.image ? (
          <Col md="4" sm="12" className="hero-quote__img-container col--no-gutters">
            <Image
              imageData={module.image}
              alt={module.image.altText}
              className="hero-quote__image"
            />
          </Col>
        ) : null}
        <Col
          {...(module.image ? { md: '8' } : { md: '12' })}
          xl="8"
          sm="12"
          className="hero-quote__content col--no-gutters"
        >
          <q className="hero-quote__quote">{module.text}</q>
          <div className="hero-quote__info">
            <QuoteAuthor name={module.authorName} jobTitle={module.jobTitle} />
            {module.link ? (
              <LinkItem
                link={module.link}
                linkClass="hero-quote__link link link--pink"
                titleClass="hero-quote__link-text"
                hasIcon
                isLink
                showTitle
              />
            ) : null}
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

export default HeroQuote;
