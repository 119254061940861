import React, { FC } from 'react';

import LinkItem from 'common/LinkItem';

import { TextBlockProps } from './models';

import './TextBlock.scss';

const TextBlock: FC<TextBlockProps> = ({ textBlockDisplayed, ctaLink, text }) => {
  if (!textBlockDisplayed) return null;

  return (
    <div className="full-width-video__text-block">
      <h2 className="full-width-video__title h4">{text}</h2>
      <LinkItem
        link={ctaLink}
        linkClass="full-width-video__link link link--white-black"
        titleClass="full-width-video__link-text"
        hasIcon
        isLink
        showTitle
      />
    </div>
  );
};

export default TextBlock;
