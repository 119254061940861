import React, { FC } from 'react';

import Image from 'common/Image';

import TextBlock from '../TextBlock';
import { ImageItemProps } from './models';

import './ImageItem.scss';

const ImageItem: FC<ImageItemProps> = ({ image, textBlock, textBlockDisplayed, ariaId }) => {
  const { ctaLink, text } = textBlock || { ctaLink: [], text: '' };

  return (
    <>
      <Image
        className="full-width-video__image"
        imageData={image}
        alt={image.altText}
        aria-describedby={ariaId}
      />
      {textBlock ? (
        <TextBlock textBlockDisplayed={textBlockDisplayed} ctaLink={ctaLink} text={text} />
      ) : null}
    </>
  );
};

export default ImageItem;
