import React, { FC } from 'react';

import { QuoteAuthorProps } from './models';

import './QuoteAuthor.scss';

const QuoteAuthor: FC<QuoteAuthorProps> = ({ name, jobTitle }) => {
  if (!name && !jobTitle) return null;

  return (
    <div className="quote-author">
      {name ? <h2 className="quote-author__name h6">{name}</h2> : null}
      {jobTitle ? <span className="quote-author__job caption">{jobTitle}</span> : null}
    </div>
  );
};

export default QuoteAuthor;
