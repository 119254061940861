import React, { FC } from 'react';

import Image from 'common/Image';

import { ImageItemProps } from './models';

const ImageItem: FC<ImageItemProps> = ({ image, ariaId }) => {
  if (!image) return null;

  return (
    <Image
      imageData={image}
      alt={image.altText}
      className="video-and-image-with-text__image"
      aria-describedby={ariaId}
    />
  );
};

export default ImageItem;
