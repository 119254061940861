import React, { FC, useRef } from 'react';
import classNames from 'classnames';

import { Col, Row } from 'layout';
import Image from 'common/Image';
import LinkItem from 'common/LinkItem';

import useOnScreen from '../../../../hooks/useOnScreen';
import { ImageCardProps } from './models';

import './ImageCard.scss';

const ImageCard: FC<ImageCardProps> = ({ title, sectionName, image, ctaLink }) => {
  const visibleRef = useRef(null);
  const isVisible = useOnScreen(visibleRef, 0);

  return (
    <Col key={title} className="image-cards__item col--no-gutters" xl="4" md="4" sm="12">
      <div
        className={classNames('image-cards__item-holder', { 'image-cards__animation': isVisible })}
        ref={visibleRef}
      >
        <Row>
          <Col xl="12" className="image-cards__item-top col--no-gutters">
            <span className="image-cards__item-span">{sectionName}</span>
            <h2 className="image-cards__item-title">{title}</h2>
          </Col>
          <Col xl="12" className="image-cards__item-bottom col--no-gutters">
            <Image imageData={image} alt={image.altText} className="image-cards__item-img" />
          </Col>
        </Row>
        <LinkItem
          link={ctaLink}
          linkClass="image-cards__item-link-card"
          title=" "
          ariaLabel={title}
          hasIcon={false}
          isLink
          showTitle={false}
        />
      </div>
    </Col>
  );
};

export default ImageCard;
