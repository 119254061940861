import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import objectHash from 'object-hash';

import { Col, Container, Row } from 'layout';
import Caption from 'common/Caption';
import VideoIframe from 'components/VideoIframe';
import VideoYouTube from 'components/VideoYouTube';
import { commonColorHandler } from 'utils/colorsHandler';

import ImageItem from './ImageItem';
import { FullWidthVideoProps } from './models';
import TextBlock from './TextBlock';

import './FullWidthVideo.scss';

const FullWidthVideo: FC<FullWidthVideoProps> = ({ module }) => {
  const [textBlockDisplayed, setTextBlockDisplayed] = useState<boolean>(false);

  const ariaId: string = objectHash(module);

  useEffect(() => {
    if (!module.textBlock) return;
    setTextBlockDisplayed(true);
  }, []);

  const showTextBlock = () => {
    setTextBlockDisplayed(true);
  };

  const hideTextBlock = () => {
    setTextBlockDisplayed(false);
  };

  return (
    <section
      className={classNames(
        'full-width-video section',
        commonColorHandler(module.backgroundColorFullWidthVideo?.[0]),
        {
          'full-width-video--with-text': module.textBlock && !module.isTextBlockOnTop,
          'full-width-video--with-text-top': module.textBlock && module.isTextBlockOnTop,
        }
      )}
    >
      <Container>
        <Row>
          <Col className="full-width-video__container col--no-gutters">
            {!module.video ? (
              <ImageItem
                image={module.image}
                textBlock={module.textBlock}
                textBlockDisplayed={textBlockDisplayed}
                ariaId={ariaId}
              />
            ) : !module.textBlock && module.video ? (
              <VideoIframe
                url={module.video}
                placeholderImage={module.image}
                playButtonText={module.playVideoButtonText}
                ariaId={ariaId}
              />
            ) : (
              <>
                <VideoYouTube
                  video={module.video}
                  placeholderImage={module.image}
                  playButtonText={module.playVideoButtonText}
                  onPlayHandle={hideTextBlock}
                  onPauseHandle={showTextBlock}
                  onEndHandle={showTextBlock}
                  onStateChangeHandle={hideTextBlock}
                  ariaId={ariaId}
                />
                <TextBlock
                  textBlockDisplayed={textBlockDisplayed}
                  ctaLink={module.textBlock?.ctaLink}
                  text={module.textBlock?.text}
                />
              </>
            )}
          </Col>
          {module.captionText ? (
            <Col className="col--no-gutters">
              <Caption text={module.captionText} ariaId={ariaId} isRte />
            </Col>
          ) : null}
        </Row>
      </Container>
    </section>
  );
};

export default FullWidthVideo;
