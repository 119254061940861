import React, { FC } from 'react';

import { IntroSectionProps } from './models';

import './IntroSection.scss';

const IntroSection: FC<IntroSectionProps> = ({ heading, introText }) => {
  if (!heading && !introText) return null;

  return (
    <div className="colourful-quote-slider__intro-holder">
      {heading ? <h2 className="colourful-quote-slider__title h4">{heading}</h2> : null}
      {introText ? <p className="colourful-quote-slider__text standfirst">{introText}</p> : null}
    </div>
  );
};

export default IntroSection;
