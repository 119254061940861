import React, { FC, useContext, useRef } from 'react';
import classNames from 'classnames';

import { Col } from 'layout';
import LinkItem from 'common/LinkItem';

import ScreenRecognitionContext from '../../../../contexts/screenRecognitionContext';
import useOnScreen from '../../../../hooks/useOnScreen';
import { CtaCardProps } from './models';

import './CtaCard.scss';

const CtaCard: FC<CtaCardProps> = ({ card }) => {
  const { isDesktop, isLargeDesktop } = useContext(ScreenRecognitionContext);

  const divRef = useRef(null);
  const isDivVisible = useOnScreen(divRef, 0);

  return (
    <>
      <Col key={card.title} className="color-cards__col col--no-gutters" xl="4" md="4" sm="12">
        <div
          className={classNames('color-cards__wrap', { 'color-cards__animation': isDivVisible })}
          ref={divRef}
        >
          <span className="color-cards__span">{card.sectionName}</span>
          <LinkItem
            link={card.ctaLink}
            linkClass="color-cards__link-card"
            hasIcon={false}
            title={card.title}
            isLink
            showTitle={false}
          />
          <div className="color-cards__bottom">
            <h3
              className={classNames('color-cards__title', {
                h5: isDesktop || isLargeDesktop,
                h6: !isDesktop && !isLargeDesktop,
              })}
            >
              {card.title}
            </h3>
            <LinkItem
              link={card.ctaLink}
              linkClass="color-cards__link link link--pink"
              titleClass="color-cards__link-text"
              hasIcon
              isLink={false}
              showTitle
            />
          </div>
        </div>
      </Col>
    </>
  );
};

export default CtaCard;
