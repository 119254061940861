import React, { FC, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';

import { Container } from 'layout';
import CustomCarousel from 'components/CustomCarousel';

import ColourfulQuoteSlide from './ColourfulQuoteSlide';
import IntroSection from './IntroSection';
import { ColourfulQuoteSliderProps } from './models';

import './ColourfulQuoteSlider.scss';

const ColourfulQuoteSlider: FC<ColourfulQuoteSliderProps> = ({ module }) => {
  const [padding, setPadding] = useState<number>(0);
  const divRef = useRef<HTMLDivElement>(null);
  const sectionRef = useRef<HTMLElement>(null);
  const [indexesOfItemWithoutImage, setIndexesOfItemWithoutImage] = useState<number[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleArrowAlignment = () => {
    const prevBtn = sectionRef.current?.querySelector(
      '.colourful-quote-slider .custom-control-arrow.control-prev'
    );
    const nextBtn = sectionRef.current?.querySelector(
      '.colourful-quote-slider .custom-control-arrow.control-next'
    );
    const dots = sectionRef.current?.querySelector('.colourful-quote-slider .control-dots');

    if (sectionRef.current && indexesOfItemWithoutImage.includes(selectedIndex)) {
      prevBtn?.classList.add('colourful-quote-slider__btn-prev');
      nextBtn?.classList.add('colourful-quote-slider__btn-next');
      dots?.classList.add('colourful-quote-slider__dots');
    } else {
      prevBtn?.classList.remove('colourful-quote-slider__btn-prev');
      nextBtn?.classList.remove('colourful-quote-slider__btn-next');
      dots?.classList.remove('colourful-quote-slider__dots');
    }
  };

  useEffect(() => {
    module.items?.forEach((item, index) => {
      if (!item.image) {
        setIndexesOfItemWithoutImage((prev) => [...prev, index]);
      }
    });

    const handleResize = debounce(() => {
      setPadding(Number(divRef.current?.clientHeight));
    }, 500);
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleArrowAlignment();
  }, [selectedIndex, indexesOfItemWithoutImage]);

  const handleIndex = (selectedindex) => {
    setSelectedIndex(selectedindex);
  };

  return (
    <section ref={sectionRef} className="colourful-quote-slider">
      <Container className="colourful-quote-slider__container">
        <div ref={divRef} className="colourful-quote-slider__intro">
          <IntroSection heading={module.heading} introText={module.introText} />
        </div>
        {module.items.length === 1 ? (
          <ColourfulQuoteSlide
            item={module.items[0]}
            isHeading={!!module.heading}
            isIntroText={!!module.introText}
            padding={padding}
            isSingleItem
          />
        ) : (
          <CustomCarousel
            infiniteLoop
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            onChange={handleIndex}
          >
            {module.items?.map((item) => (
              <ColourfulQuoteSlide
                item={item}
                isHeading={!!module.heading}
                isIntroText={!!module.introText}
                padding={padding}
                isSingleItem={false}
                key={item.text}
              />
            ))}
          </CustomCarousel>
        )}
      </Container>
    </section>
  );
};

export default ColourfulQuoteSlider;
