import React, { FC } from 'react';
import classNames from 'classnames';

import { Container, Row } from 'layout';

import ImageCard from './ImageCard';
import { ImageCtaCardsProps } from './models';

import './ImageCtaCards.scss';

const ImageCtaCards: FC<ImageCtaCardsProps> = ({ cards, greyBackground }) => (
  <section
    className={classNames('image-cards', {
      'image-cards--grey section-bg': greyBackground,
      section: !greyBackground,
    })}
  >
    <Container>
      <Row className="row--margin row--center">
        {cards?.map((card) => (
          <ImageCard
            key={card.title}
            title={card.title}
            sectionName={card.sectionName}
            image={card.image}
            ctaLink={card.ctaLink}
          />
        ))}
      </Row>
    </Container>
  </section>
);

export default ImageCtaCards;
