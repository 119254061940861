import React, { FC, useContext, useMemo } from 'react';
import YouTube from 'react-youtube';

import { isBrowser } from 'utils/browser';
import { getYouTubeId } from 'utils/iframeHandler';

import LanguageContext from '../../contexts/languageContext';
import ScreenRecognitionContext from '../../contexts/screenRecognitionContext';
import { Options, VideoYouTubeProps } from './models';
import YouTubeWithImage from './YouTubeWithImage';

import './VideoYouTube.scss';

const VideoYouTube: FC<VideoYouTubeProps> = ({
  video,
  placeholderImage,
  playButtonText,
  onPlayHandle,
  onPauseHandle,
  onEndHandle,
  onStateChangeHandle,
  ariaId,
}) => {
  const lang = useContext(LanguageContext);
  const videoId = useMemo(() => (video ? getYouTubeId(video) : undefined), [video]);
  const protocol = isBrowser() ? window.location.protocol : 'https';
  const { isTablet, isMobile, isSmallMobile } = useContext(ScreenRecognitionContext);

  const opts: Options = {
    host: `${protocol}//www.youtube-nocookie.com`,
    playerVars: {
      autoplay: placeholderImage ? 1 : 0,
      cc_load_policy: 1,
      cc_lang_pref: String(lang),
      hl: String(lang),
    },
  };

  const onPlayerReady = (e) => {
    e.target.getIframe().setAttribute('aria-describedby', ariaId);
    if (isMobile || isSmallMobile || isTablet) e.target.mute();
    e.target.playVideo();
  };

  return (
    <div className="youtube-with-image">
      {placeholderImage ? (
        <YouTubeWithImage
          videoId={videoId}
          opts={opts}
          onReadyHandle={onPlayerReady}
          onPlayHandle={onPlayHandle}
          onPauseHandle={onPauseHandle}
          onEndHandle={onEndHandle}
          onStateChangeHandle={onStateChangeHandle}
          playButtonText={playButtonText}
          placeholderImage={placeholderImage}
          ariaId={ariaId}
        />
      ) : (
        <YouTube
          videoId={videoId}
          opts={opts}
          onPlay={onPlayHandle}
          onPause={onPauseHandle}
          onEnd={onEndHandle}
          onStateChange={onStateChangeHandle}
          className="youtube-with-image__video"
          aria-describedby={ariaId}
        />
      )}
    </div>
  );
};

export default VideoYouTube;
