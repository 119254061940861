import React, { FC } from 'react';
import classNames from 'classnames';

import { Container, Row } from 'layout';
import { commonColorHandler } from 'utils/colorsHandler';

import CtaCard from './CtaCard';
import { ColorCtaCardsProps } from './models';

import './ColorCtaCards.scss';

const ColorCtaCards: FC<ColorCtaCardsProps> = ({ cards, colorStyle }) => (
  <section
    className={classNames(`color-cards ${commonColorHandler(colorStyle[0])}`, {
      'section-bg':
        commonColorHandler(colorStyle[0]) === 'color-cards--white-cards-gray-background',
      section: commonColorHandler(colorStyle[0]) !== 'color-cards--white-cards-gray-background',
    })}
  >
    <Container>
      <Row className="color-cards__row row--center">
        {cards?.map((card) => (
          <CtaCard key={card.title} card={card} />
        ))}
      </Row>
    </Container>
  </section>
);

export default ColorCtaCards;
