import React, { FC, useContext } from 'react';
import classNames from 'classnames';

import { Col, Row } from 'layout';
import Image from 'common/Image';
import { commonColorHandler } from 'utils/colorsHandler';

import ScreenRecognitionContext from '../../../contexts/screenRecognitionContext';
import AuthorInfo from '../AuthorInfo';
import { ColourfulQuoteSlideProps } from './models';

import './ColourfulQuoteSlide.scss';

const ColourfulQuoteSlide: FC<ColourfulQuoteSlideProps> = ({
  item,
  isHeading,
  isIntroText,
  isSingleItem,
  padding,
}) => {
  const { isDesktop, isLargeDesktop, isTablet } = useContext(ScreenRecognitionContext);

  return (
    <div
      className={classNames(
        'colourful-quote-slider__holder',
        commonColorHandler(item.backgroundColor[0]),
        {
          'colourful-quote-slider__holder--single': isSingleItem,
        }
      )}
      style={
        isHeading || isIntroText
          ? {
              paddingTop: `${
                isLargeDesktop || isDesktop
                  ? padding + 260
                  : isTablet
                  ? padding + 250
                  : padding + 181
              }px`,
            }
          : {}
      }
    >
      <Row>
        {item.image ? (
          <Col xl="4" md="12" className="col--no-gutters colourful-quote-slider__col-image">
            <Image
              imageData={item.image}
              alt={item.image.altText}
              className="colourful-quote-slider__image"
            />
          </Col>
        ) : null}
        <Col xl="8" md="12" className="col--no-gutters">
          <div
            className={classNames('colourful-quote-slider__content', {
              'colourful-quote-slider__content--with-image': item.image,
              'colourful-quote-slider__content--single': isSingleItem,
            })}
          >
            <p className="colourful-quote-slider__quote standfirst">{item.text}</p>
            <AuthorInfo name={item.authorName} jobTitle={item.authorJobTitle} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ColourfulQuoteSlide;
